import {useEffect, useState} from "react";
import {TonConnectButton, useTonConnectModal, useTonConnectUI} from "@tonconnect/ui-react";
import {Fragment} from 'react'

import '../index.css';
import {Card, CardContent, Stack, Typography} from "@mui/joy";
import {useNavigate} from "react-router-dom";

const Auth = () => {
    const nav = useNavigate()
    const {open: openModal} = useTonConnectModal();
    const [tonConnectUI] = useTonConnectUI();

    tonConnectUI.setConnectRequestParameters({
        state: 'ready',
        value: {
            tonProof: '8f6cec5c4a4596557e38f82038dbeeee1a6279a662e0a25988dd72cc50d10091'
        }
    });

    useEffect(() => {
        if (localStorage.getItem("access-token")) {
            nav("/")
        }
    }, []);

    useEffect(() => {
        tonConnectUI.onStatusChange(async (wallet) => {
            if (!wallet) {
                return;
            }

            const req = await fetch("https://api.tonbags.io/v1/method/tonconnect/auth.validate", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ...wallet
                })

            })

            const res = await req.json()

            if (res.valid) {
                localStorage.setItem("access-token", res.data.token)
                nav("/")
            } else {
                alert(res.message)
            }
        }, console.error);
    }, [tonConnectUI]);

    return (
        <>
            <CardContent sx={{alignItems: 'center', textAlign: 'center', justifyContent: "center", height: "100vh"}}>
            <Card
                sx={{
                    width: "400px",
                    minHeight: "300px"
                    }}
            >
                <CardContent sx={{alignItems: 'center', justifyContent: "center", textAlign: 'center'}}>
                    <Stack
                        direction={'column'}
                        spacing={0}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{marginBottom: 2}}
                    >
                        <Typography level="h3">Authtorization</Typography>
                        <Typography level="body-sm" sx={{ opacity: '50%' }}>
                            To use TON Bags you need to connect your TON wallet
                        </Typography>
                    </Stack>

                    <TonConnectButton onClick={openModal}/>
                </CardContent>
            </Card>
            </CardContent>
        </>
    )
}

export default Auth