import {useEffect, useState} from "react";
import {TonConnectButton, useTonConnectModal, useTonConnectUI} from "@tonconnect/ui-react";
import {Fragment} from 'react'

import '../index.css';
import {Card, CardContent, Stack, Typography} from "@mui/joy";
import {useNavigate} from "react-router-dom";

const Home = () => {
    const nav = useNavigate()

    useEffect(() => {
        if (!localStorage.getItem("access-token")) {
            nav("/auth")
        }
    }, []);

    return (
        <>
            <CardContent sx={{alignItems: 'center', textAlign: 'center', justifyContent: "center", height: "100vh"}}>
                Fuck you
            </CardContent>
        </>
    )
}

export default Home