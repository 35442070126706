import {BrowserRouter, HashRouter, Route, Routes} from "react-router-dom";

import Auth from "./views/Auth";
import Home from "./views/Home";

const App = () => {
  return (
      <>
        <HashRouter>
          <Routes>
              <Route path="/" element={<Home />} />
            <Route path="/auth" element={<Auth />} />
          </Routes>
        </HashRouter>
      </>
  )
}

export default App